//const {nb} = await import(o365.getLibUrl('o365.locales.nb.js'))
import number from 'o365.modules.utils.number.js';
import { userSession } from 'o365.modules.configs.ts';
//import { number as NumberInput } from 'o365.libs.vue-number-format.3.2.0.js';
import { component as NumberInput } from 'vue-number-format';
import ODatePicker from 'o365.vue.components.DatePicker.vue';
import { ref, computed, defineComponent, watch } from 'vue';
import useErrorCapture from 'o365.vue.composables.ErrorCapture.ts';

const OTextEditor = {
    name: 'OTextEditor',
    props: ['modelValue', 'column'],
    emits: ['update:modelValue'],
    setup(props, context) {
        const elRef = ref(null);

        const internalValue = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                context.emit('update:modelValue', value);
            }
        });

        function activateEditor(pOptions) {
            elRef.value?.focus();
            if (elRef.value && pOptions?.selectionRange) {
                elRef.value.setSelectionRange(pOptions.selectionRange[0], pOptions.selectionRange[1])
                elRef.value.scrollLeft = 0
            }
        }

        function setElRef(el) {
            elRef.value = el;
        }

        context.expose({ activateEditor, elRef });

        return () => <input type="text" v-model={internalValue.value} ref={setElRef} />;
    },
};


const ONumberEditor = defineComponent({
    name: 'ONumberEditor',
    props: {
        modelValue: [Number, null, String],
        column: Object,
        format: String,
        returnAsString: Boolean,
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const elRef = ref(null);

        const internalValue = ref(null);
        const oldInternalValue = ref(null);

        const isAtDecimalEnd = ref(false);
        function parseDecimals(value, index) {
            var splitDecimal = value.split(userSession.decimalSeparator ?? '.')
            if (splitDecimal.length > 1) {
                var substring = splitDecimal[1].substring(0, index)
                var combinedString = `${splitDecimal[0].replace(/\s+/g, '')}.${substring.replace(/\D/g, '')}`;
                return parseFloat(combinedString);
            } else if (index != 0) {
                if (splitDecimal[0].length > index) {
                    if (splitDecimal[0].includes('-0')) {
                        var combinedString = splitDecimal[0].slice(0, 2) + '.' + splitDecimal[0][splitDecimal[0].length - 1];
                        return parseFloat(combinedString);
                    }
                    if (oldInternalValue?.value && oldInternalValue?.value.includes(userSession.decimalSeparator ?? '.')) {
                        var substring = splitDecimal[0].slice(-index).substring(0, index)
                        var removed = splitDecimal[0].slice(0, -index);
                        var combinedString = `${removed.replace(/\s+/g, '')}.${substring.replace(/\D/g, '')}`;

                        return parseFloat(combinedString);
                    } else {

                        var zeros = '';
                        for (let i = 0; i < index; i++) {
                            zeros += "0";
                        }
                        var combinedString = `${splitDecimal[0].replace(/\s+/g, '')}.${zeros.replace(/\D/g, '')}`;
                        return parseFloat(combinedString);
                    }

                } else {
                    return parseFloat(splitDecimal[0].replace(/\s+/g, ''));

                }



            }
            return parseFloat(value.replace(/\s+/g, ''));
        }
        let lastValueWasInvalid = false;
        const computedModelValue = computed({
            get() {
                return internalValue.value ?? ''; F
            },
            set(newValue) {
                lastValueWasInvalid = false;
                if (newValue == null || (format.value && newValue === '')) {
                    internalValue.value = null;
                    context.emit('update:modelValue', null);
                } else if (newValue !== '') {
                    try {
                        if (typeof newValue === 'string' && newValue.includes(',')) { newValue = newValue.replace(',', '.'); }
                        let parsed = format.value
                            ? parseDecimals(elRef.value.maskedValue, elRef.value.precision)
                            : parseFloat(newValue);
                        if (Number.isNaN(parsed)) {
                            lastValueWasInvalid = true;
                            return;
                        }
                        if (format.value && numberOptions.value.rawValueDivisor) {
                            parsed = parsed / numberOptions.value.rawValueDivisor;
                        }
                        // internalValue.value = parsed;
                        setInternalValue(parsed);

                        context.emit('update:modelValue', props.returnAsString ? (parsed == null ? null : `${parsed}`) : parsed);
                    } catch (ex) {
                        lastValueWasInvalid = true;
                    }
                } else {
                    lastValueWasInvalid = true;
                }
            }
        });

        const numberOptions = computed(() => {
            return number.getVueNumberFormatOptions(format.value);
        })

        const format = computed(() => {
            return props.format ?? props.column?.format;
        });

        function handleKeyDown(e) {
            if (!format.value || !numberOptions.value) {
                return;
            }
            if (!numberOptions.value.decimal) {
                return;
            }

            const el = elRef.value.$el;

            const separatorIndex = el.value.indexOf(numberOptions.value.decimal) + 1;
            if (separatorIndex !== -1 && separatorIndex !== 0) {
                isAtDecimalEnd.value = el.selectionStart > separatorIndex && el.value.substring(separatorIndex).length - (numberOptions.value.suffix ? 1 : 0) >= numberOptions.value.precision;
            }
            if (e.key !== "." && e.key !== ",") {
                if (el.selectionStart > separatorIndex && !isNaN(parseInt(e.key)) && e.key !== " " && !isAtDecimalEnd.value) {
                    e.target.value = e.target.value.substring(0, el.selectionStart) + e.key + e.target.value.substring(el.selectionStart + 1)
                    e.stopImmediatePropagation();
                    e.preventDefault();
                    const inputEvent = new Event('input', {
                        bubbles: true,
                        cancelable: true,
                        target: e.target,
                    });
                    e.target.dispatchEvent(inputEvent);
                }
                return;
            } else if (separatorIndex == 0) {
                return;
            } else {
                el.selectionStart = separatorIndex;
            }
        }

        function isValidElValue() {
            if (format.value) {
                return true;
            } else {
                return elRef.value && (elRef.value.valueAsNumber || elRef.value.valueAsNumber === 0);
            }
        }

        function onBlur() {
            if (lastValueWasInvalid) {
                if (!isValidElValue()) {
                    internalValue.value = null;
                    context.emit('update:modelValue', null);

                    if (!format.value && elRef.value) {
                        elRef.value.value = '';
                    }
                } else {
                    setInternalValue(props.modelValue);
                }
            }
        }

        function activateEditor(pOptions) {
            const el = elRef.value?.$el ? elRef.value.$el : elRef.value;

            el?.focus();
            if (el && props.modelValue && `${props.modelValue}`.length == 1 && format.value) {
                el.setSelectionRange(1, 1);
                return;
            }
            if (el && pOptions?.selectionRange) {
                el.setSelectionRange(pOptions.selectionRange[0], pOptions.selectionRange[1])
                el.scrollLeft = 0;
                return;
            }
            if (el && el.value && numberOptions.value && numberOptions.value.decimal && el.value.indexOf(numberOptions.value.decimal) > -1) {
                const vStart = el.value.indexOf(numberOptions.value.decimal);
                el.setSelectionRange(vStart, vStart);
            }
        }

        function setElRef(el) {
            elRef.value = el;
        }

        function setInternalValue(value) {
            if (value && format.value && numberOptions.value.rawValueDivisor) {
                internalValue.value = value * numberOptions.value.rawValueDivisor
            } else {
                if (internalValue.value === value) {
                    if (elRef.value && elRef.value.value != value) {
                        elRef.value.value = value;
                    }
                }
                internalValue.value = value
            }

            oldInternalValue.value = internalValue.value
        }

        setInternalValue(props.modelValue);
        watch(() => props.modelValue, (newValue) => {
            setInternalValue(newValue);
        });

        const [capturedError, ErrorRenderer] = useErrorCapture({
            consoleMessagee: `Error encountered when trying to render NumberEditor`,
            errorRenderFunctionOptions: {
                uiMessage: 'NumberEditor Render Error',
                uiTitleMessage: 'An error has occurred when rendering this NumberEditor',
                type: 'span'
            }
        });

        context.expose({ activateEditor, elRef });

        return () => capturedError.value ? <ErrorRenderer />
            : format?.value
                ? <NumberInput modelValue={computedModelValue.value} onKeydown={handleKeyDown} onInput:modelValue={val => computedModelValue.value = val} {...numberOptions.value} ref={setElRef} onBlur={onBlur} />
                : <input type="text" v-model={computedModelValue.value} ref={setElRef} onBlur={onBlur} />;
    }
});

const OBitEditor = {
    name: 'OBitEditor',
    props: ['modelValue', 'column'],
    emits: ['update:modelValue'],
    setup(props, context) {
        const elRef = ref(null);

        const internalValue = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                context.emit('update:modelValue', value);
            }
        });

        function activateEditor() {
            elRef.value?.focus();
        }

        function setElRef(el) {
            elRef.value = el;
        }

        context.expose({ activateEditor, elRef });

        return () => <input type="checkbox" v-model={internalValue.value} ref={setElRef} />;
    },
};

const ODateEditor = {
    name: 'ODateEditor',
    props: ['modelValue', 'column', 'format', 'timepicker', 'openOnIconClick'],
    emits: ['update:modelValue'],
    setup(props, context) {
        const elRef = ref(null);

        const internalValue = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                context.emit('update:modelValue', value);
            }
        });

        const format = computed(() => {
            return props.format ?? props.column.format;
        });

        const timepicker = computed(() => {
            return props.column?.type == 'time' || (props.timepicker ?? props.column?.type === 'datetime') && format.value !== 'Short Date';
        });
        const outputTimeString = computed(() => {
            return props.column?.type == 'time';
        })

        const openOnIconClick = computed(() => {
            return props.openOnIconClick ? true : false;
        });

        function activateEditor() {
            //elRef.value?.datepickerRef.closeMenu();
            //elRef.value?.datepickerRef.openMenu();
            elRef.value?.activateEditor();
        }

        function setElRef(el) {
            elRef.value = el;
        }

        context.expose({ activateEditor, elRef });

        return () => <ODatePicker v-model={internalValue.value}
            column={props.column}
            ref={setElRef}
            format={format.value}
            date={props.column.type === 'date'}
            utc={props.column.utc ? 'preserve' : undefined}
            timepicker={timepicker.value}
            outputTimeString={outputTimeString.value}
            containerClass="dp-override text-truncate d-flex flex-1"
            class="text-truncate rounded-0"
            teleport={true}
            openOnIconClick={openOnIconClick.value} />
    },
};
const OTimeEditor = {
    name: 'OTimeEditor',
    props: ['modelValue', 'column', 'format', 'openOnIconClick'],
    emits: ['update:modelValue'],
    setup(props, context) {
        const elRef = ref(null);

        const internalValue = computed({
            get() {
                return props.modelValue;
            },
            set(value) {
                context.emit('update:modelValue', value);
            }
        });

        const format = computed(() => {
            return props.format ?? props.column.format;
        });

        const timepicker = computed(() => {
            return true;
        });

        const openOnIconClick = computed(() => {
            return props.openOnIconClick ? true : false;
        });

        function activateEditor() {
            //elRef.value?.datepickerRef.closeMenu();
            //elRef.value?.datepickerRef.openMenu();
            elRef.value?.activateEditor();
        }

        function setElRef(el) {
            elRef.value = el;
        }

        context.expose({ activateEditor, elRef });

        return () => <ODatePicker v-model={internalValue.value}
            column={props.column}
            ref={setElRef}
            format={format.value}

            utc={props.column.utc ? 'preserve' : undefined}
            timepicker={timepicker.value}
            containerClass="dp-override text-truncate d-flex flex-1"
            class="text-truncate rounded-0"
            teleport={true}
            openOnIconClick={openOnIconClick.value} />
    },
};


export { OTextEditor, ONumberEditor, OBitEditor, ODateEditor, OTimeEditor }